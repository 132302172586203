<template>
  <transition
      appear
      enter-active-class="animate__fadeInUpBig"
      leave-active-class="animate__fadeOutDownBig"
      name="animate__animated animate__bounce animate__faster"
  >
    <div v-if="advancedSetting && advancedSetting.length > 0"
         v-show="visible"
         class="vr-advancedSetting-box"
         @click.stop="initClick"
         @contextmenu.stop="() => {}">
      <div class="swiper swiper2">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in advancedSetting" :key="index" class="swiper-slide" @click="currentIndex = index" @click.stop="slideClick(item)">
            <div
                :class="[
                {'gif1': item.icon === 'gif'}
                ]"
                :style="{
                'background-image': 'url(' + item.image + ')',
                  }" class="icon"></div>
            <div class="vr-advancedSetting-title">
              <div :class="[
                    {
                      animate_title: animateTitleCalc(index),
                    },
                    {
                      cur: currentIndex === index
                    }
                  ]" class="name">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      currentIndex: null
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    vrAppInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    advancedSetting() {
      let v = []
      try {
        let vrConfig = this.vrAppInfo.vrConfig
        v = JSON.parse(vrConfig.advancedSetting)
      } catch (e) {
        console.error('加载高级功能失败', e)
        v = []
      }
      
      if(v){
        v = v.sort((a, b) => {
          return a.sort - b.sort
        })
      }
      return v
    },


  },
  mounted() {

  },

  methods: {
    initClick() {
      this.$emit('initClick')
    },
    animateTitleCalc(index) {
      let nodeAll = document.querySelectorAll('.vr-advancedSetting-title')
      if (!nodeAll) return false
      let node = nodeAll[index]
      if (!node) return false
      let childWidth = node.querySelector('span').offsetWidth
      return childWidth > node.clientWidth
    },
    slideClick(item) {
      console.log(item);
      this.$emit('change', {...item})
    }
  },
  watch: {
    visible(val) {
      this.show = val
    },
  },
}
</script>
<style lang="less" scoped>
@icon-width: 35px;
.vr-advancedSetting-box {
  position: absolute;
  bottom: 1vh;
  left: 0;
  width: 100%;
  height: 6vh;
  //background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  border-radius: 6px;
  display: flex;
  justify-content: center;

  .swiper2 {
    .swiper-wrapper {
      display: flex;

      .swiper-slide {
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .icon {
          object-fit: cover;
          background-repeat: no-repeat;
          width: @icon-width;
          height: @icon-width;
          background-size: cover;

          &.gif1 {
            background-size: @icon-width calc(@icon-width * 12);
            animation: gif1 1.2s steps(12) infinite;
          }
        }

        .vr-advancedSetting-title {
          font-size: 12px;
          color: #fff;
          width: 80px;
          overflow: hidden;

          .name {
            text-align: center;
            white-space: nowrap;

            &.cur.animate_title {
              width: max-content;
              padding-left: 100%;
              animation: animate_title_frames 4s linear 0s infinite normal;
            }
          }


        }
      }
    }
  }
}

@keyframes gif1 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -@icon-width * 12;
  }
}

@media screen and (max-width: 770px) {
  .vr-advancedSetting-box {
    .swiper2 {
      .swiper-wrapper {
        .swiper-slide {
          width: 60px;

          .vr-advancedSetting-title {
            width: 60px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@keyframes animate_title_frames {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
