import Vue from 'vue'
import { axios } from '@/utils/request'

const api = {
  user: '/mock/api/user',
  role: '/mock/api/role',
  service: '/mock/api/service',
  permission: '/mock/api/permission',
  permissionNoPager: '/mock/api/permission/no-pager'
}

export default api

// 透露微服务网关地址
export const microService = {
  sysrural: '/sysrural', // 智慧乡村-新闻
  systrace: '/systrace', // 溯源
  sysklbase:'/sysklbase', // 平台内容运营
  sysbase: '/sysbase', // 用户信息部分-公共
  device: '/xdzs-iotdevice', // 设备部分-物联网
  sysaiot: '/sysaiot', // 基地部分 vr部分-物联网
  // sysbase: '', // 用户信息部分
  // device: '/xdzs-iotdevice', // 设备部分
  // sysaiot: '', // 基地部分 vr部分
}

//post
export function postAction(url, parameter) {
  return axios({
    url: url,
    method: 'post',
    data: parameter
  })
}

//post method= {post | put}
export function httpAction(url, parameter, method, arg) {
  return axios({
    url: url,
    method: method,
    data: parameter,
    ...arg
  })
}

//put
export function putAction(url, parameter) {
  return axios({
    url: url,
    method: 'put',
    data: parameter
  })
}

//get
export function getAction(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter
  })
}

//deleteAction
export function deleteAction(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    params: parameter
  })
}

export function getUserList(parameter) {
  return axios({
    url: api.user,
    method: 'get',
    params: parameter
  })
}

export function getRoleList(parameter) {
  return axios({
    url: api.role,
    method: 'get',
    params: parameter
  })
}

export function getServiceList(parameter) {
  return axios({
    url: api.service,
    method: 'get',
    params: parameter
  })
}

export function getPermissions(parameter) {
  return axios({
    url: api.permissionNoPager,
    method: 'get',
    params: parameter
  })
}

// id == 0 add     post
// id != 0 update  put
export function saveService(parameter) {
  return axios({
    url: api.service,
    method: parameter.id == 0 ? 'post' : 'put',
    data: parameter
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return axios({
    url: url,
    params: parameter,
    method: 'get',
    responseType: 'blob'
  })
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, fileName, parameter) {
  return downFile(url, parameter).then((data) => {
    if (!data || data.size === 0) {
      Vue.prototype['$message'].warning('文件下载失败')
      return
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      let url = window.URL.createObjectURL(new Blob([data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) //下载完成移除元素
      window.URL.revokeObjectURL(url) //释放掉blob对象
    }
  })
}

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
export function uploadAction(url, parameter) {
  return axios({
    url: url,
    data: parameter,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',  // 文件上传
    },
  })
}

/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar, subStr) {
  if (!subStr) subStr = 'http'
  try {
    if (avatar && avatar.startsWith(subStr)) {
      return avatar;
    } else {
      if (avatar && avatar.length > 0 && avatar.indexOf('[') == -1) {
        return  'http://120.77.2.32:9119' + "/" + avatar;
      }
    }
  } catch (err) {
    return;
  }
}

export function getInfoUrl(type) {
  let url = ''
  switch (type) {
    case '1':
      url = '/gardens/parkGreenSpace/queryById'
      break
    case '2':
      url = '/gardens/productivePlantationArea/queryById'
      break
    case '3':
      url = '/gardens/famousTree/queryById'
      break
    case '4':
      url = '/gardens/attachedGreenSpace/queryById'
      break
    case '5':
      url = '/gardens/greeBuffer/queryById'
      break
    case '6':
      url = '/gardens/squareGreenSpace/queryById'
      break
    case '7':
      url = '/gardens/otherGreenSpace/queryById'
      break
    case '8':
      url = '/gardens/roadGreenSpace/queryById'
      break
    case '9':
      url = ''
      break
    case '10':
      url = ''
      break
    case '11':
      url = ''
      break
    case '12':
      url = ''
      break
    case '13':
      url = '/gardens/verticalGreen/queryById'
      break
    case '14':
      url = '/gardens/roofGreen/queryById'
      break
    case '15':
      url = ''
      break
    case '16':
      url = '/gardens/sysGardensParkFacilities/queryById'
      break
    case '17':
      url = ''
      break
  }
  return url
}
