var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"appear":"","enter-active-class":"animate__fadeInUpBig","leave-active-class":"animate__fadeOutDownBig","name":"animate__animated animate__bounce animate__faster"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"vr-switch-scene-box",on:{"click":function($event){$event.stopPropagation();return _vm.initClick.apply(null, arguments)},"contextmenu":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupDatas && _vm.groupDatas.length > 1),expression:"groupDatas && groupDatas.length > 1"}],staticClass:"group-box"},_vm._l((_vm.groupDatas),function(item,index){return _c('div',{key:index,class:[
          {
            active: _vm.currentGroupIndex === index,
          },
        ],on:{"click":function($event){return _vm.setGroup(index)}}},[_vm._v(" "+_vm._s(item.groupName)+" ")])}),0),_c('div',{staticClass:"vr-switch-scene"},[_c('div',{staticClass:"swiper",attrs:{"id":"swiperIndex"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.groupDatas[_vm.currentGroupIndex]
              ? _vm.groupDatas[_vm.currentGroupIndex].vrInfoSceneList
                ? _vm.groupDatas[_vm.currentGroupIndex].vrInfoSceneList
                : []
              : []),function(item,index){return _c('div',{key:index,staticClass:"vr-switch-scene-item swiper-slide",class:[
              {
                'vr-switch-scene-item-active': index === _vm.currentIndex,
              },
            ],on:{"contextmenu":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)},"click":function($event){$event.stopPropagation();return (() => {
                _vm.setCurrent(index)
                _vm.initClick()
              }).apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.replaceImage(item.showUrl),"alt":""}}),_c('div',{staticClass:"vr-switch-scene-item-title"},[_c('div',{staticClass:"name",class:[
                  {
                    animate_title: _vm.animateTitleCalc(index),
                  },
                ]},[_c('span',[_vm._v(_vm._s(item.title))])])])])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }