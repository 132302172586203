/**
 * 复制
 */
 export function copy(text) {
  return new Promise((resolve, reject) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        navigator.clipboard.writeText(text).then(
          function () {
            resolve(true)
          },
          function () {
            reject(false)
          }
        )
      } else {
        let input = document.createElement('input', {
          style: 'display:none',
        })
        input.value = text // 修改文本框的内容
        document.body.appendChild(input)
        input.select() // 选中文本
        document.execCommand('Copy')
        document.body.removeChild(input)
        resolve(true)
      }
    } catch (e) {
      reject(e)
    }
  })
}
