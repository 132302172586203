export default {
  computed: {
    getBodyWidth() {
      let body = document.body
      return body.clientWidth
    }
  },
  methods: {
    isDesktop() {
      return this.getBodyWidth > 700
    },
    isMobile() {
      return this.getBodyWidth <= 700
    },
    isEmptyArray(arr, len = 0) {
      if (Array.isArray(arr)) {
        return arr.length === len
      } else {
        return true
      }
    }
  }
}
