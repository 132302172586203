import Vue from 'vue'
import VueRouter from 'vue-router'
import vr from '@/views/vr/index.vue'
import SysDevice7 from '@/views/vr/SysDevice7/index.vue'
import Scene from '@/views/Scene/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: vr
  },
  {
    path: '/SysDevice7',
    component: SysDevice7
  },
  {
    path: '/Scene',
    component: Scene
  }
]

const router = new VueRouter({
  mode: 'history',
  base: window.__MICRO_APP_BASE_ROUTE__ ||  process.env.BASE_URL,
  routes
})

export default router
