<template>
  <vr :vrUrl="vrUrl" @closeVr="closeVr" v-if="isVRT"></vr>

  <div class="vr-page-container" @contextmenu.prevent="showContextMenu" @click.prevent="destroyContextMenu"
    :style="{ background: background }" v-else>
    <div id="viewer"></div>

    <audio v-show="false" ref="musicAudio" src="" style="width: 0; height: 0"></audio>
    <audio v-show="false" ref="voiceAudio" src="" style="width: 0; height: 0"></audio>

    <transition appear enter-active-class="animate__fadeInUpBig" leave-active-class="animate__fadeOutDownBig"
      name="animate__animated animate__bounce animate__faster">
      <div v-show="showObj.footerShow" class="footer-action">
        <div class="pl cur">
          <div v-show="trActionShow({ key: 21 })" class="action_item" @contextmenu.stop="() => { }" @click.stop="() => {
            showObj.switchSceneShow = !showObj.switchSceneShow
            initPlayAudio()
          }">
            <div class="sceneIcon">
              <svg-icon v-if="showObj.switchSceneShow" icon-class="vr_scene_on"></svg-icon>
              <svg-icon v-else icon-class="vr_scene_off"></svg-icon>
            </div>
            <span>切换场景</span>
          </div>
        </div>
        <div class="pr">
          <!--          <div class="action_item" @click.stop="star" @contextmenu.stop="() => {}">-->
          <!--            <div class="sceneIcon">-->
          <!--              <svg-icon v-if="showObj.switchSceneShow" icon-class="vr_star_before"></svg-icon>-->
          <!--              <svg-icon v-else icon-class="vr_star_after"></svg-icon>-->
          <!--            </div>-->
          <!--            <span>3</span>-->
          <!--          </div>-->
          <div v-show="isDesktop() && trActionShow({ key: 20 })" class="action_item" @click.stop="share"
            @contextmenu.stop="() => { }">
            <div class="sceneIcon">
              <svg-icon icon-class="vr_share"></svg-icon>
            </div>
            <span>分享</span>
          </div>
        </div>
      </div>
    </transition>
    <transition appear enter-active-class="animate__fadeInRightBig" leave-active-class="animate__fadeOutRightBig"
      name="animate__animated animate__bounce animate__faster">
      <div v-show="showObj.tpShow" class="tr-action">
        <div v-for="(item, index) in trAction" class="action_item" @click.stop="trIconClick(item.key)"
          v-show="trActionShow(item, index)" :key="index">
          <div class="sceneIcon">
            <a :title="iconClassDescTitle(item.key)" style="color:#fff;">
              <svg-icon :icon-class="iconClassMethod(item.key)"></svg-icon>
            </a>
          </div>
        </div>
      </div>
    </transition>
    <transition appear enter-active-class="animate__fadeInLeftBig" leave-active-class="animate__fadeOutLeftBig"
      name="animate__animated animate__bounce animate__faster">
      <div v-show="showObj.logoShow" class="logo-spane">
        <!--        <div class="t"><span>访问量: 12</span></div>-->
        <div class="logo">
          <a href=""> <img :src="logoImgSrc" alt="" /></a>
        </div>
      </div>
    </transition>
    <div v-if="showGuid" class="guid">
      <img :src="vrGuidImg" alt="" />
    </div>

    <switchScene v-if="vrAppInfo.id" ref="switchScene" :visible="showObj.switchSceneShow" :vr-app-info="vrAppInfo"
      @change="sceneChange" @click="initPlayAudio" @initClick="initPlayAudio"></switchScene>
    <share v-if="showShare" :select-scene-data="{
      currentGroupIndex: currentGroupIndex,
      currentSceneIndex: currentSceneIndex,
      vrAppInfo: vrAppInfo,
    }" @close="showShare = false"></share>
    <advanced-setting v-if="vrAppInfo.id" ref="advancedSetting" :visible="showObj.advancedSettingShow"
      :vr-app-info="vrAppInfo" @change="advancedSettingChange" @click="initPlayAudio"
      @initClick="initPlayAudio"></advanced-setting>

    <vrIot v-if="isIot" ref="vrIot" @change="closePopup"></vrIot>
    <vrHtml v-if="isHtml" ref="vrHtml" @change="closePopup"></vrHtml>
    <vrText v-if="isText" ref="vrText" @change="closePopup"></vrText>
    <vrImage v-if="isImage" ref="vrImage" @change="closePopup"></vrImage>
    <vrVideo v-if="isVideo" ref="vrVideo" @change="closePopup"></vrVideo>
    <pest-light v-if="isLight" ref="vrLight" @change="closePopup"></pest-light>
  </div>
</template>

<script>
import switchScene from "./components/switchScene";
import share from "./components/share";
import vrIot from "./components/vrIot";
import vrHtml from "./components/vrHtml";
import vrText from "./components/vrText";
import vrImage from "./components/vrImage";
import vrVideo from "./components/vrVideo";
import pestLight from "./components/pestLight"; //虫情灯

import vr from "./components/vr";

const logoImg = require("@/assets/logo.png");
const vrGuidImg = require("@/assets/vr_guid.gif");
import { getAction, getFileAccessHttpUrl, microService } from "@api/manage";

//vr
import { Viewer, Animation } from "photo-sphere-viewer";

import { MarkersPlugin } from "photo-sphere-viewer/dist/plugins/markers"; //标注插件
import { CompassPlugin } from "photo-sphere-viewer/dist/plugins/compass"; //指南针插件
import { VisibleRangePlugin } from "photo-sphere-viewer/dist/plugins/visible-range"; //可见范围插件
import { EquirectangularTilesAdapter } from 'photo-sphere-viewer/dist/adapters/equirectangular-tiles'; //瓦片插件

import { GyroscopePlugin } from "photo-sphere-viewer/dist/plugins/gyroscope"; //陀螺仪插件
import { StereoPlugin } from "photo-sphere-viewer/dist/plugins/stereo"; //立体
// import {StereoPlugin} from "photo-sphere-viewer/dist/plugins/stereo-plugin"; //立体插件

import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import "photo-sphere-viewer/dist/plugins/markers.css";
import "photo-sphere-viewer/dist/plugins/compass.css";
import "photo-sphere-viewer/dist/plugins/virtual-tour.css";
import advancedSetting from "./components/advancedSetting/advancedSetting";

export default {
  name: "index.vue",
  components: {
    switchScene,
    share,
    vrIot,
    vrHtml,
    vrText,
    vrImage,
    vrVideo,
    pestLight,
    advancedSetting,
    vr
  },
  data() {
    return {
      source: 0, // 来源取地址栏参数 等于1的话 不显示全屏按钮
      logoImg: logoImg,
      vrGuidImg: vrGuidImg,
      screen: false,
      f11Screen: false,
      loop: true,
      description: "",
      isVr: false,
      isMusic: true,
      isVoice: true,
      isMarker: false,
      showShare: false,
      showGuid: false,
      isVRT: false,
      showObj: {
        switchSceneShow: true,
        footerShow: true,
        tpShow: true,
        logoShow: true,
        advancedSettingShow: true,
      },
      trAction: [
        { key: 1 },
        { key: 2 },
        // {key: 3}, 
        { key: 4 },
        { key: 5 }
      ],
      vrAppInfo: {
        vrConfig: {},
        vrScene: [],
      },
      currentGroupIndex: 0,
      currentSceneIndex: 0,
      vrId: 0,

      isIot: false,
      isHtml: false,
      isImage: false,
      isText: false,
      isVideo: false,
      isLight: false,

      //VR 开始
      viewer: null,
      isFirst: true,
      vrUrl: "",
      vrOpeningStyle: [
        {
          title: "无",
          type: "normal",
          properties: "",
        },
        {
          title: "小行星开场",
          type: "asteroid",
          properties: {
            lat: { start: -Math.PI / 2, end: 0 },
            long: { start: Math.PI, end: 0 },
            zoom: { start: 250, end: 50 },
            fisheye: { start: 2, end: 0 },
            minFov: { start: 125, end: 30 },
            maxFov: { start: 179, end: 90 },
          },
          properties2: {
            lat: { start: 0, end: -Math.PI / 2 },
            long: { start: 0, end: Math.PI },
            zoom: { start: 50, end: 250 },
            fisheye: { start: 0, end: 2 },
            minFov: { start: 120, end: 125 },
            maxFov: { start: 90, end: 179 },
          },
        },
        {
          title: "水平巡游",
          type: "roam",
          properties: {
            lat: { start: 0, end: 0 },
            long: { start: Math.PI, end: 0 },
            zoom: { start: 250, end: 50 },
            fisheye: { start: 1, end: 0 },
            minFov: { start: 30, end: 30 },
            maxFov: { start: 90, end: 90 },
          },
          properties2: {
            lat: { start: 0, end: 0 },
            long: { start: 0, end: Math.PI },
            zoom: { start: 50, end: 250 },
            fisheye: { start: 0, end: 1 },
            minFov: { start: 30, end: 30 },
            maxFov: { start: 90, end: 90 },
          },
        },
        {
          title: "小行星巡游",
          type: "asteroidRoam",
          properties: {
            lat: { start: -Math.PI / 2, end: 0 },
            long: { start: Math.PI, end: 0 },
            zoom: { start: 250, end: 50 },
            fisheye: { start: 2, end: 0 },
            minFov: { start: 125, end: 30 },
            maxFov: { start: 179, end: 90 },
          },
          properties2: {
            lat: { start: 0, end: -Math.PI / 2 },
            long: { start: 0, end: Math.PI },
            zoom: { start: 50, end: 250 },
            fisheye: { start: 0, end: 2 },
            minFov: { start: 30, end: 125 },
            maxFov: { start: 90, end: 179 },
          },
        },
        {
          title: "水晶球开场",
          type: "sphere",
          properties: {
            lat: { start: -Math.PI / 2, end: 0 },
            long: { start: Math.PI, end: 0 },
            zoom: { start: 250, end: 50 },
            fisheye: { start: 4, end: 0 },
            minFov: { start: 90, end: 30 },
            maxFov: { start: 179, end: 90 },
          },
          properties2: {
            lat: { start: 0, end: -Math.PI / 2 },
            long: { start: 0, end: Math.PI },
            zoom: { start: 50, end: 140 },
            fisheye: { start: 0, end: 4 },
            minFov: { start: 30, end: 90 },
            maxFov: { start: 60, end: 179 },
          },
        },
        {
          title: "小行星缩放",
          type: "asteroidZoom",
          properties: {
            lat: { start: -Math.PI / 2, end: 0 },
            long: { start: Math.PI, end: 0 },
            zoom: { start: 50, end: 0 },
            fisheye: { start: 2, end: 0 },
            minFov: { start: 105, end: 30 },
            maxFov: { start: 159, end: 90 },
          },
          properties2: {
            lat: { start: 0, end: -Math.PI / 2 },
            long: { start: 0, end: Math.PI },
            zoom: { start: 50, end: 250 },
            fisheye: { start: 0, end: 2 },
            minFov: { start: 30, end: 105 },
            maxFov: { start: 90, end: 159 },
          },
        },
        {
          title: "鱼眼开场",
          type: "fisheye",
          properties: {
            lat: { start: 0, end: 0 },
            long: { start: Math.PI, end: 0 },
            zoom: { start: 50, end: 50 },
            fisheye: { start: 1, end: 0 },
            minFov: { start: 30, end: 30 },
            maxFov: { start: 179, end: 90 },
          },
          properties2: {
            lat: { start: 0, end: 0 },
            long: { start: 0, end: Math.PI },
            zoom: { start: 50, end: 50 },
            fisheye: { start: 0, end: 1 },
            minFov: { start: 30, end: 30 },
            maxFov: { start: 90, end: 129 },
          },
        },
      ],

      //vr地图
      vrMap: [
        {
          id: "1543074397443756033",
          url: "/imgs/vr/quanjing6.jpg",
          title: "全景图",
        },
      ],

      //信息点 id 标识 title 标题 content 内容 position 立体位置 jump 场景跳转名
      infospots: [],
      markersPlugin: null,
      stereoPlugin: null,

      sceneList: [],
      sceneNum: 0,
      sceneParams: {
        width: 0,
        xnum: 0,
        ynum: 0
      },
      longLats: [0, 0],

      image: '',
      isFirstInitAudio: true,
      // background: 'url(https://psge.sidwit.com/rysev/temp/bj2_1658192759512.jpg) center center no-repeat'
      background: 'url(https://psge.sidwit.com/rysev/temp/db-bj.jpg) center center no-repeat'
    }
  },
  computed: {
    logoImgSrc() {
      let url = logoImg;
      if (this.vrAppInfo.id) {
        let config = this.vrAppInfo.vrConfig;
        try {
          let globalHeader = JSON.parse(config.globalHeader);
          if (globalHeader.image) {
            return getFileAccessHttpUrl(globalHeader.image);
          }
        } catch (e) {
        }
      }
      return url;
    },
  },
  mounted() {

  },
  created() {
    let vrId = this.$route.query.vrId;
    let source = this.$route.query.source
    this.vrId = vrId;
    this.source = source
    this.getVrAppInfo(vrId);
  },
  methods: {
    // 请求应用信息
    getVrAppInfo(id) {
      if (id == '1567794812660801538') {
        if (this.isMobile()) {
          this.background = 'url(https://psge.sidwit.com/rysev/temp/db-bj1.jpg?v=20220913) center center no-repeat';
        }
        else {
          this.background = 'url(https://psge.sidwit.com/rysev/temp/db-bj.jpg?v=20220913) center center no-repeat';
        }
      }
      else if (id == '1586204538448711682') {
        if (this.isMobile()) {
          this.background = 'url(https://psge.sidwit.com/rysev/temp/bj-sbc-mc.jpg?v=20220913) center center no-repeat';
        }
        else {
          this.background = 'url(https://psge.sidwit.com/rysev/temp/bj-sbc.jpg?v=20220913) center center no-repeat';
        }
      }
      else if (id == '1548241525390139394') {
        if (this.isMobile()) {
          this.background = 'url(/imgs/mc.jpg) center center no-repeat';
        }
        else {
          this.background = 'url(/imgs/pc.jpg) center center no-repeat';
        }
      }
      else {
        if (this.isMobile()) {
          this.background = 'url(https://psge.sidwit.com/dbcicon/temp/qj-m_1678758988671.jpg) center center no-repeat';
        }
        else {
          this.background = 'url(https://psge.sidwit.com/dbcicon/temp/qj_1678758982717.jpg) center center no-repeat';
        }
      }
      getAction(microService.sysaiot + "/openapi/v2/vr/info/sceneList", {
        vrId: id || "1542757032059371522",
      }).then((r) => {
        console.log('r', r);
        if (r.code === 200) {
          let appInfo = {
            ...r.result.vrInfo,
            vrConfig: r.result.vrConfig,
            vrScene: r.result.vrScene,
          };
          document.title = r.result.vrInfo.name;
          this.vrAppInfo = appInfo;
        }
      });
    },
    initPlayAudio() {
      if (!this.isFirstInitAudio) return
      this.$refs.musicAudio.play()
      this.$refs.voiceAudio.play()
      this.isFirstInitAudio = false
    },
    // 初始化音乐音效
    initAudio() {
      try {
        let data = JSON.parse(this.vrAppInfo.vrConfig.globalSoundEffects)
        let scene = this.vrAppInfo.vrScene[this.currentGroupIndex].vrInfoSceneList[this.currentSceneIndex]
        data = JSON.parse(data[scene.id])
        let musicSrc = this.$refs.musicAudio.src
        let regex = RegExp(/\.(mp3|wav|WMA|MP2|Flac|MIDI|RA|APE|AAC|CDA|MOV)/ig)
        if (data.audio && musicSrc.search(regex) === -1) {
          this.$refs.musicAudio.src = data.audio
          this.$refs.musicAudio.volume = (data.volume / 100) || 0.5
          this.$refs.musicAudio.loop = true
          this.$refs.musicAudio.play()
          this.isMusic = true
        } else {
          if (musicSrc.search(regex) === -1) {
            this.$refs.musicAudio.pause();
            this.isMusic = false;
          }

        }
        if (data.commentary) {
          this.$refs.voiceAudio.loop = true
          this.$refs.voiceAudio.src = data.commentary
          this.$refs.voiceAudio.volume = (data.commentaryVolume / 100) || 0.5
          this.$refs.voiceAudio.play()
          this.isVoice = true
        } else {
          this.$refs.voiceAudio.pause();
          this.isVoice = false;
        }
      } catch (e) {
        console.error("音频初始化失败", e);
      }
    },
    isScreen() {
      let bool = true;
      if (!this.screen) {
        bool = false;
      }
      Object.keys(this.showObj).map((r) => {
        this.showObj[r] = bool;
      });
      this.screen = !this.screen;
    },
    isScreen2(type) {
      Object.keys(this.showObj).map((r) => {
        this.showObj[r] = type
      })
      this.screen = type
    },
    iconClassMethod(key) {
      switch (key) {
        case 1:
          return "vr_screen";
        case 2:
          if (this.loop) {
            return "vr_loop_on";
          } else {
            return "vr_loop_off";
          }
        case 3:
          if (this.isVr) {
            return "vr_vr";
          } else {
            return "vr_vr";
          }
        case 4:
          if (this.isMusic) {
            return "vr_music_on";
          } else {
            return "vr_music_off";
          }
        case 5:
          if (this.isVoice) {
            return "vr_voice_on";
          } else {
            return "vr_voice_off";
          }
        default:
          return "";
      }
    },
    iconClassDescTitle(key) {
      switch (key) {
        case 1:
          return "全屏";
        case 2:
          return "循环";
        case 3:
          return "VR";
        case 4:
          return "音乐";
        case 5:
          return "语音";
        default:
          return "";
      }
    },
    trIconClick(key) {
      this.initPlayAudio()
      switch (key) {
        case 1:
          this.onClickFullScreen();
          break;
        case 2:
          // 是否循环
          if (this.viewer.isAutorotateEnabled()) {
            this.viewer.stopAutorotate();
          } else {
            this.viewer.startAutorotate();
          }
          this.loop = !this.loop;

          break;
        case 3:
          if (this.viewer) {
            this.viewer.destroy();
          }
          // this.stereoPlugin.isEnabled();
          this.isVRT = true;
          // this.stereoPlugin.toggle();
          break;
        case 4:
          if (!this.isMusic) {
            this.$refs.musicAudio.play();
          } else {
            this.$refs.musicAudio.pause();
          }
          this.isMusic = !this.isMusic;
          break;
        case 5:
          if (!this.isVoice) {
            this.$refs.voiceAudio.play();
          } else {
            this.$refs.voiceAudio.pause();
          }
          this.isVoice = !this.isVoice;
          break;
        default:
          break;
      }
    },
    onClickFullScreen() {
      if (this.f11Screen) {
        // 退出
        if (document.exitFullScreen) {
          document.exitFullscreen();
        }
        //兼容火狐
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        //兼容谷歌等
        if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        //兼容IE
        if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 全屏
        if (document.documentElement.RequestFullScreen) {
          document.documentElement.RequestFullScreen();
        }
        //兼容火狐
        if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        }
        //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
        if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen();
        }
        //兼容IE,只能写msRequestFullscreen
        if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      }
      this.f11Screen = !this.f11Screen;
    },
    // 分享
    share() {
      this.showShare = !this.showShare
      this.initPlayAudio()
    },
    // 点赞
    star() {
    },
    // 右键菜单
    showContextMenu(event) {
      // 先监查有没有未销毁的
      if (document.querySelector(".menu.vr-context-menu")) {
        document.body.removeChild(
          document.querySelector(".menu.vr-context-menu")
        );
      }
      this.$contextmenu({
        items: [
          {
            label: "全屏切换",
            divided: true,
            onClick: () => {
              this.destroyContextMenu();
              this.onClickFullScreen();
            },
          },
          // {label: '改变拖拽形式', divided: true},
          {
            label: '正常视角',
            divided: true,
            onClick: () => {
              this.setOpenStyle(1);
              this.destroyContextMenu();
              this.isScreen2(true);
            },
          },
          {
            label: "鱼眼视角",
            divided: true,
            onClick: () => {
              this.setOpenStyle(6, 1);
              this.destroyContextMenu();
              this.isScreen2(false);
            },
          },
          {
            label: "小行星视角",
            divided: true,
            onClick: () => {
              this.setOpenStyle(1, 1);
              this.destroyContextMenu();
              this.isScreen2(false);
            },
          },
          {
            label: "水晶球视角",
            divided: true,
            onClick: () => {
              this.setOpenStyle(4, 1);
              this.destroyContextMenu();
              this.isScreen2(false);
            },
          },
        ],
        event,
        //x: event.clientX,
        //y: event.clientY,
        customClass: "vr-context-menu",
        zIndex: 10000000,
        minWidth: 230,
      });
      return false;
    },

    destroyContextMenu() {
      if (document.querySelector(".menu.vr-context-menu")) {
        document.body.removeChild(
          document.querySelector(".menu.vr-context-menu")
        );
      }
      return false;
    },
    // 场景切换
    sceneChange(v) {
      this.currentGroupIndex = v.currentGroupIndex;
      this.currentSceneIndex = v.currentSceneIndex;

      let scene = v.datas[v.currentGroupIndex].vrInfoSceneList[v.currentSceneIndex];
      this.vrUrl = scene;
      if (scene.defaultPerspective) {
        this.longLats = scene.defaultPerspective.split(",");
      }

      if (this.isFirst) {
        if (scene.width) {
          this.sceneParams = {
            width: scene.width,
            xnum: scene.xnum,
            ynum: scene.ynum
          }
        }

        this.init();
      }

      this.initAudio();
      this.getScene(scene);
    },

    //关闭弹出框
    closePopup(name) {
      switch (name) {
        case "iot":
          this.isIot = false;
          break;
        case "html":
          this.isHtml = false;
          break;
        case "text":
          this.isText = false;
          break;
        case "image":
          this.isImage = false;
          break;
        case "video":
          this.isVideo = false;
          break;
        case "light":
          this.isLight = false;
          break;
        default:
          break;
      }

      this.viewer.startAutorotate();
    },

    /* 初始化 */
    init(width, xnum, ynum) {
      if (this.viewer) {
        this.viewer.destroy();
      }

      this.setViewer(width, xnum, ynum);
      this.setOpenStyle(1);
    },

    setViewer() {
      let ViewerParams = {
        container: document.querySelector("#viewer"),
        navbar: false,
        panorama: "",
        plugins: [
          [VisibleRangePlugin, {}],
          [MarkersPlugin],
          // [GyroscopePlugin],
          [GyroscopePlugin, {
            touchmove: true,
            absolutePosition: false,
          }],
          [StereoPlugin],
        ],
        autorotateSpeed: 0.08,
        autorotateIdle: true,
        // captureCursor: true
        loadingTxt: '加载中……'
      };

      if (this.sceneParams.width) {
        ViewerParams.adapter = EquirectangularTilesAdapter;
        ViewerParams.panorama = {
          width: 2000,
          cols: 16,
          rows: 8,
          baseUrl: 'https://psge.sidwit.com/rysev/temp/bj_1658128363846.jpg',
          tileUrl: (col, row) => {
            const num = row * 16 + col + 1;
            return `panorama_${('000' + num).slice(-3)}.jpg`;
          },
        };
      }

      this.viewer = new Viewer(ViewerParams)

      this.stereoPlugin = this.viewer.getPlugin(StereoPlugin);

      // this.viewer.isEnabled();

      this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);
      this.clickMarkers();

      //点击全景图 4秒钟后重新选择
      this.viewer.on('click', (e, data) => {
        this.initPlayAudio();
        this.viewer.stopAutorotate();
        this.loop = false;
        setTimeout((_) => {
          this.loop = true;
          this.viewer.startAutorotate();
        }, 4000)
      })

      this.viewer.on('stop-all', (e, data) => {
        if (this.viewer.isAutorotateEnabled()) {
          setTimeout((_) => {
            if (!this.isMarker) {
              this.loop = true;
              this.viewer.startAutorotate();
              this.isMarker = false;
            }
          }, 4000)
        }
      })
    },

    //获取场景
    getScene(scene) {
      this.infospots = [];
      if (scene.infospots) {
        this.infospots = JSON.parse(scene.infospots);
      }

      //暂时调用的本地图片 解决跨域 后期删除
      scene.url = scene.url.replace(/http:\/\/120.77.2.32:9119/g, 'https://psge.sidwit.com');
      scene.url = scene.url.replace(/http:\/\/img.xd.sidwit.com/g, 'https://psge.sidwit.com');
      scene.url = scene.url.replace(/http:\/\/vrjs.xd.sidwit.com/g, 'https://psge.sidwit.com');

      this.setScene(scene);
    },

    //设置场景
    setScene(scene) {
      let that = this;
      this.markersPlugin.clearMarkers();
      let params = { longitude: 0, latitude: 0 };
      if (scene.defaultPerspective) {
        let defaultPerspective = scene.defaultPerspective.split(",");
        params = { longitude: defaultPerspective[0], latitude: defaultPerspective[1] };
      }

      let sceneParams2 = scene.url;
      if (this.sceneParams.width) {
        let scenePath = scene.url.replace(/s.jpg/g, '');
        scene.url = scene.url.replace(/http:\/\/img.xd.sidwit.com/g, 'https://psge.sidwit.com');
        scene.url = scene.url.replace(/http:\/\/vrjs.xd.sidwit.com/g, 'https://psge.sidwit.com');
        sceneParams2 = {
          width: this.sceneParams.width,
          cols: this.sceneParams.ynum,
          rows: this.sceneParams.xnum,
          baseUrl: scene.url,
          tileUrl: (col, row) => {
            return `${scenePath}${col}_${row}.jpg`;
          },
        };
      }

      if (this.sceneParams.width) {
        if (!this.isFirst) {
          this.viewer.startAutorotate();
        } else {
          this.isFirst = false;
        }

        if (this.infospots.length > 0) {
          this.infospots.forEach((item) => {
            this.markersPlugin.addMarker(item);
          });
        }

        //设置全景图
        this.viewer.setPanorama(sceneParams2, params, true);
      }
      else {
        //设置全景图
        this.viewer.setPanorama(sceneParams2, params, true).then(function () {
          if (that.infospots.length > 0) {
            that.infospots.forEach((item) => {
              that.markersPlugin.addMarker(item);
            });
          }

          if (!that.isFirst) {
            that.viewer.startAutorotate();
          } else {
            that.isFirst = false;
          }
        })
      }

      if (this.vrId == '1548241525390139394') {
        if (scene.id != '1548877993510895618') {
          const visibleRangePlugin = this.viewer.getPlugin(VisibleRangePlugin)
          visibleRangePlugin.setLatitudeRange(['38deg', '-179deg'])
        }
        else {
          const visibleRangePlugin = this.viewer.getPlugin(VisibleRangePlugin)
          visibleRangePlugin.setLatitudeRange(null)
        }
      }
    },

    //点击焦点
    clickMarkers() {
      let that = this;
      this.markersPlugin.on("select-marker", function (e, marker, data) {
        // console.log(marker);
        let title = marker.config.tooltip ? marker.config.tooltip.content : marker.config.html;

        if (marker.config.sceneId) {
          that.$nextTick(() => {
            that.$refs.switchScene.setSceneIndex(marker.config.sceneId);
          })
        } else if (marker.config.videoHls) {
          that.isVideo = true;
          that.$nextTick(() => {
            that.$refs.vrVideo.centerBoxs(marker, marker.config.videoHls, 'videoHls');
          })
        } else if (marker.config.video) {
          that.isVideo = true;
          that.$nextTick(() => {
            that.$refs.vrVideo.centerBoxs(marker, marker.config.video, 'video');
          })
        } else if (marker.config.light && marker.config.light.length > 0) {
          that.isLight = true;
          that.$nextTick(() => {
            that.$refs.vrLight.centerBoxs(title, marker.config.light);
          });
        } else if (marker.config.deviceList && JSON.stringify(marker.config.deviceList) != "{}") {
          that.isIot = true;
          that.$nextTick(() => {
            that.$refs.vrIot.centerBoxs(title, marker.config.deviceList);
          })
        } else if (marker.config.imageList && marker.config.imageList.length > 0) {
          that.isVideo = true;
          that.$nextTick(() => {
            that.$refs.vrVideo.centerBoxs(marker, marker.config.imageList, 'img');
          })
        } else {
          that.isHtml = true;
          that.$nextTick(() => {
            that.$refs.vrHtml.centerBoxs(title, marker.config.content);
          })
        }

        that.loop = false;
        that.isMarker = true;
        that.viewer.stopAutorotate();
      });
    },
    // 高级功能点击
    advancedSettingChange(item) {
      // console.log(item);
      let marker = {
        config: {}
      }
      switch (+item.type) {
        case 2:
          marker.config.imageList = item.value;
          this.isVideo = true;
          this.$nextTick(() => {
            this.$refs.vrVideo.centerBoxs(marker, item.value, 'img');
          })
          break;
        case 3:
          marker.config.imageList = [item.value];
          this.isVideo = true;
          this.$nextTick(() => {
            this.$refs.vrVideo.centerBoxs(marker, [item.value], 'img');
          })
          break;
        case 4:
          this.isVideo = true;
          marker.config.video = item.value;
          this.$nextTick(() => {
            this.$refs.vrVideo.centerBoxs(marker, marker.config.video, 'video');
          })
          break;
        case 7:
          this.isHtml = true;
          this.$nextTick(() => {
            this.$refs.vrHtml.centerBoxs(item.title, item.content);
          })
          break
      }
    },

    //设置开场样式
    setOpenStyle(index, reverse) {
      let that = this;
      let vrProperties = this.vrOpeningStyle[index].properties;
      let duration = 8000;
      if (reverse) {
        vrProperties = this.vrOpeningStyle[index].properties2
        duration = 1000;
      }
      this.viewer.renderer.camera.far *= 2;

      if (this.longLats[0] != 0) {
        vrProperties.long.end = this.longLats[0];
      }

      if (this.longLats[1] != 0) {
        vrProperties.lat.end = this.longLats[1];
      }

      if (vrProperties) {
        let anim = new Animation({
          properties: vrProperties,
          duration: duration,
          easing: "inOutQuad",
          onTick: (properties) => {

            this.viewer.setOptions({
              fisheye: properties.fisheye,
              minFov: properties.minFov,
              maxFov: properties.maxFov,
            });
            this.viewer.rotate({
              longitude: properties.long,
              latitude: properties.lat,
            });
            this.viewer.zoom(properties.zoom);
          },
        }).then(() => {
          if (reverse && index == 1) {
            that.viewer.stopAutorotate();
          } else if (reverse) {
            that.viewer.startAutorotate();
          } else {
            that.$nextTick((_) => {
              if (this.isDesktop()) {
                this.showGuid = true
                setTimeout((_) => {
                  this.showGuid = false
                }, 2000)
              }
            })
            that.viewer.startAutorotate();
          }
        })
      }
    },

    trActionShow(item, index) {
      if (item.key === 1 && +this.source === 1) {
        return false
      }

      if (this.$route?.query?.trAction?.split(',')?.map(item1 => +item1)?.indexOf(item.key) === -1) {
        return false
      }
      return true
    },
    closeVr(scene) {
      this.isVRT = false;
      // this.getScene(this.vrUrl);
      if (scene.width) {
        this.sceneParams = {
          width: scene.width,
          xnum: scene.xnum,
          ynum: scene.ynum
        }
      }
      this.init();

      this.getScene(scene);
    }
  },
  beforeDestroy() {
  }
}
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
<style lang="less">
.vr-context-menu {
  background: rgba(0, 0, 0, 0.5) !important;

  .menu_item_label {
    color: white !important;
  }

  .menu_item__available:hover {
    background: white;

    .menu_item_label {
      color: black !important;
    }
  }
}

.psv-tooltip,
.psv-panel {
  display: none !important;
}

// .psv-loader-canvas {
//   display: none !important;
// }

.psv-container {
  background: none !important;
}

.gif {
  animation: gif 1.2s steps(12) infinite;
  background-size: 1em 12em;
}

@keyframes gif {
  from {
    background-position: 0 0px;
  }

  to {
    background-position: 0 -12em;
  }
}

.audioMb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000000;
}
</style>