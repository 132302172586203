<template>
  <div class="pestLight" v-if="visible">
    <img src="/imgs/vr/close.png" class="close" @click="handleClose"></img>
    <div class="top">
      <p>{{title}}</p>
      <p>{{time}}</p>
      <div class="top_left">
        <img src="" alt="">
        <div>
            <img src="/imgs/vr/img_left.png" alt="" class="img_left" @click="handleLeft">
            <div>
                <div id="dom">
                    <img src="" alt="" v-for="(item,i) in imgList" :key="i" @click="handleSelect(item,i)" :class="idx==i?'active':''">
                </div>
            </div>
            <img src="/imgs/vr/img_right.png" alt="" class="img_right" @click="handleRight">
        </div>
      </div>
      <div class="top_right">
        <div>
            <p>识别病虫总数</p>
            <div>
                <img src="/imgs/vr/kunchong.png" alt="">
                <span>580</span>
            </div>
        </div>
        <div>
            <p>累计个数</p>
            <div>
                <img src="/imgs/vr/tongji.png" alt="">
                <span>580</span>
            </div>
        </div>
      </div>
    </div>
    <div class="bottom">
        <div>
            <p>今日排行</p>
            <dv-scroll-board :config="config" style="width:100%;height:80%" />
        </div>
        <div>
            <p>最近7天每日最大数量排行</p>
            <dv-scroll-board :config="configs" style="width:100%;height:80%" />
        </div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from "@api/manage";
export default {
  name: "index",
  props: {},
  data() {
    return {
      description: "",
      visible: false,
      title: "",
      list: [],
      time: "",
      translate:0,
      idx: 0,
      imgList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      config: {
        header: ["名称", "数量(只)"],
        data: [
          ["行1列1", "行1列2"],
          ["行2列1", "行2列2"],
          ["行3列1", "行3列2"],
          ["行4列1", "行4列2"],
          ["行5列1", "行5列2"],
          ["行6列1", "行6列2"],
          ["行7列1", "行7列2"],
          ["行8列1", "行8列2"],
          ["行9列1", "行9列2"],
          ["行10列1", "行10列2"],
        ],
      },
      configs: {
        header: ["时间", "名称", "数量(只)"],
        data: [
          ["行1列1", "行1列2", "行1列3"],
          ["行2列1", "行2列2", "行2列3"],
          ["行3列1", "行3列2", "行3列3"],
          ["行4列1", "行4列2", "行4列3"],
          ["行5列1", "行5列2", "行5列3"],
          ["行6列1", "行6列2", "行6列3"],
          ["行7列1", "行7列2", "行7列3"],
          ["行8列1", "行8列2", "行8列3"],
          ["行9列1", "行9列2", "行9列3"],
          ["行10列1", "行10列2", "行10列3"],
        ],
      },
      timer: null,
    };
  },
  methods: {
    centerBoxs(title, data) {
      this.visible = true;
      this.title = title;
    },
    //点击关闭
    handleClose() {
      this.visible = false;
      this.$emit('chenge','light');
    },
    handleLeft() {
      let dom = document.querySelector("#dom");
      if (this.translate <= -0.9) {
        this.translate += 0.9;
        dom.style = `transform:translateX(${this.translate}rem)`;
      }
    },
    handleRight() {
      let dom = document.querySelector("#dom");
      let totle = (this.imgList.length - 7) * -0.9;
      if (this.translate >= totle) {
        this.translate -= 0.9;
        dom.style = `transform:translateX(${this.translate}rem)`;
      }
    },
    handleSelect(item, index) {
      this.idx = index;
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.pestLight {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  > .close {
    width: 0.32rem;
    height: 0.32rem;
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    cursor: pointer;
  }
  > .top {
    width: 50%;
    margin: auto;
    padding-top: 7.8vh;
    height: 50%;
    > p {
      &:nth-child(1) {
        width: 100%;
        line-height: 6vh;
        text-align: center;
        font-size: 0.32rem;
        color: #f0f0f0;
        margin-bottom: 0 !important;
      }
      &:nth-child(2) {
        width: 100%;
        line-height: 2vh;
        text-align: center;
        font-size: 0.12rem;
        color: #bfbfbf;
        margin-bottom: 2vh !important;
      }
    }
    > .top_left {
      width: 68%;
      height: 39vh;
      margin-right: 2%;
      background: #757575;
      border: 1px solid #707070;
      float: left;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
      }
      > div {
        width: 100%;
        height: 1rem;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .img_left {
          width: 6px;
          position: absolute;
          top: 50%;
          left: 0.1rem;
          transform: translateY(-50%);
          cursor: pointer;
        }
        > .img_right {
          width: 6px;
          position: absolute;
          top: 50%;
          right: 0.1rem;
          transform: translateY(-50%);
          cursor: pointer;
        }
        > div {
          width: calc(100% - 12px - 0.4rem);
          height: 90%;
          margin-left: calc(6px + 0.2rem);
          overflow: hidden;
          &::-webkit-scrollbar {
            display: none;
          }
          > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            > .active {
              border: 1px solid #47ff84;
            }
            > img {
              width: 0.8rem;
              height: 0.8rem;
              margin-right: 0.1rem;
              flex-shrink: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
    > .top_right {
      width: 30%;
      height: 39vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      float: left;
      > div {
        width: 100%;
        height: 48%;
        &:nth-child(1) {
          background: #418679;
        }
        &:nth-child(2) {
          background: #41865d;
        }
        > p {
          width: 100%;
          line-height: 5vh;
          text-align: center;
          font-size: 0.2rem;
          color: #fffaff;
        }
        > div {
          width: 100%;
          height: 5vh;
          display: flex;
          align-items: center;
          > img {
            width: 0.76rem;
            height: 0.76rem;
            border: 1px solid #ffffff;
            border-radius: 50%;
            margin: 0 0.4rem 0 0.3rem;
          }
          > span {
            font-size: 0.4rem;
            color: #fffaff;
          }
        }
      }
    }
  }
  > .bottom {
    width: 50%;
    margin: auto;
    height: 44vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
    > div {
      width: 46%;
      height: 95%;
      > p {
        width: 100%;
        line-height: 4vh;
        position: relative;
        padding-left: 0.1rem;
        &::before {
          content: "";
          width: 4px;
          height: 1.5vh;
          background: #009fb9;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
/deep/ .ant-input {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #47ff84;
  color: #dddddd;
  .ant-calendar-range-picker-separator {
    background: url("../../../../../public/imgs/vr/icon_clander.png") center
      center no-repeat;
    width: 0.16rem;
    height: 0.16rem;
    vertical-align: sub;
  }
}
</style>