<template>
  <div class="mask-box">
    <div class="bg" @click.stop.prevent="close"></div>
    <span class="close" @click.stop="close"><Icon style="color: #707070" type="close"></Icon></span>
    <slot class="bodys" name="centerBox"></slot>
  </div>
</template>
<script>
import Icon from 'ant-design-vue/lib/icon'
import 'ant-design-vue/lib/icon/style/css'
export default {
  components: {
    Icon
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
.mask-box {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000000;
  top: 0;
  left: 0;
  .bg{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
  
  .close {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 36px;
    height: 36px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 43px;
    z-index: 99;
    .anticon-close {
      font-size: 24px;
    }

  }
}

@media screen and (max-width: 770px) {
  .close {
    z-index: 100000;
    background: transparent !important;

    .anticon-close {
      color: white !important;
    }
  }
}
</style>
