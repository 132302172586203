<template>
  <maskIndex @close="close">
    <div class="centerBox" slot="centerBox">
      <div class="top">
        <div class="title">{{ title }}</div>
      </div>
      <div class="html-content">
        <div v-html="content"></div>
      </div>
    </div>
  </maskIndex>

</template>

<script>
import maskIndex from '@comp/mask'
import { getAction } from "@api/manage";
export default {
  name: "index",
  components: {
    maskIndex
  },
  props: {},
  data() {
    return {
      title: "",
      content: "",
    };
  },
  methods: {
    centerBoxs(title, content) {
      // console.log("centerBoxs", title, content);
      this.content = content;
      this.title = title;
      // if(marker.config.style.margin){
      //   delete marker.config.style.margin
      // }
      // this.style = marker.config.style;
      // this.title = marker.config.tooltip?marker.config.tooltip.content:marker.config.html
      // this.img = marker.config.image?marker.config.image:''
    },
    close() {
      this.$emit("change", "html");
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>

.centerBox {
  width: 10rem;
  position: absolute;
  background: rgba(0,0,0,0.5);
  padding: 10px 20px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  .top {
    .title {
      color: #f0f0f0;
      font-size: 32px;
      line-height: 48px;
      text-shadow: 0 1px 2px 0 #000000;
      text-align: center;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 22px;
      text-align: center;
    }
  }
  .html-content {
    width: 100%;
    height: 53.7vh;
    // background: #fff;
    // color: #666;
    overflow-y: auto;
    margin-top: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      height: 100%;
      width: 100%;
    }
    /deep/img{
      max-width: 90%;
      min-width: 60%;
      height: auto;
      margin-bottom: 20px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}
.xgplayer {
  width: 100% !important;
  height: 100% !important;
}
@media screen and (max-width: 770px) {
  .centerBox{
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    top:0 !important;
    left: 0 !important;
    border-radius: 0 !important;
    .html-content{
      height: calc(100% - 78px) !important;
    }
  }
  //.close {
  //  z-index: 1;
  //  background: transparent !important;
  //
  //  .anticon-close {
  //    color: white !important;
  //  }
  //}
}
// .p{
//   animation-duration: 3s;
//   animation-name: slidein;
//   animation-iteration-count: infinite;
//   animation-direction: alternate;
//   animation-timing-function:linear;
//   background: #000;
//   width: 100%;
// }

// @keyframes slidein {
//   from {
//     margin-left: 100%;

//   }

//   to {
//     margin-left: -100%;

//   }
// }
</style>
