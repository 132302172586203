import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入ant相关全局组件
import "./ant-import"
Vue.config.productionTip = false
import '@/components/svgIcon'
import Contextmenu from 'vue-contextmenujs'
import './assets/common.less'
import commonMixins from '@/mixins/commonMixins'
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.use(Contextmenu)
Vue.mixin(commonMixins)
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueViewer);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

!function(n) {
  var e = n.document,
    t = e.documentElement,
    i = 1920,
    d = i / 100,
    o = 'orientationchange' in n ? 'orientationchange' : 'resize',
    a = function() {
      var n = t.clientWidth || 1320
      n > i && (n = i)
      t.style.fontSize = n / d + 'px'
    }
  e.addEventListener && (n.addEventListener(o, a, !1), e.addEventListener('DOMContentLoaded', a, !1))
}(window)
document.title = 'VR全景预览'
