<template>
  <mask-index @close="close">
    <div slot="centerBox" class="centerBox">
      <div class="title">{{ title }}</div>
      <!--      <div class="date">2022-100</div>-->
      <div class="video-content">
        <div v-if="fileType === 'video' || fileType === 'videoHls'" ref="video" style="width: 100%;height: 100%"></div>

        <div v-else-if="fileType === 'dahuaHls'" ref="video" class="videojs" style="width: 100%;height: 100%">
          <EasyPlayer
              ref="easyplayer"
              :decodeType="'sort'"
              :has-audio="false"
              :hasVideo="true"
              :videoUrl="dahuaHlsUrl"
              aspect
              autoplay
              live
              muted
              stretch
              style="width: 100%;height: 100%"
              @error="restartPlayer"
          ></EasyPlayer>
        </div>

        <div v-show="fileType === 'img'" class="wh100 imgsBox">
          <viewer :images="imgList" class="wh100">
            <div class="swiper1 swiper wh100">
              <div class="swiper-wrapper wh100">
                <div v-for="(item,index) in imgList" :key="index" class="swiper-slide wh100">
                  <img :src="item" alt="" class="wh100">
                </div>
              </div>
            </div>
          </viewer>
          <div v-show="!isEmptyArray(imgList,1)" :style="{top:!title?'50%':'calc(50% + 70px)'}"
               class="swiper-next swiper_navigation">
            <svg-icon icon-class="swiper_next"></svg-icon>
          </div>
          <div v-show="!isEmptyArray(imgList,1)" :style="{top:!title?'50%':'calc(50% + 70px)'}"
               class="swiper-prev swiper_navigation">
            <svg-icon icon-class="swiper_prev"></svg-icon>
          </div>
          <div v-show="!isEmptyArray(imgList,1)" class="swiper-pagination1"></div>
        </div>
      </div>
    </div>
  </mask-index>
</template>

<script>
import {getAction, postAction, microService} from '@api/manage'
import Icon from 'ant-design-vue/lib/icon'
import 'ant-design-vue/lib/icon/style/css'
import Player from 'xgplayer/dist/core_player';
import play from 'xgplayer/dist/controls/play';
import fullscreen from 'xgplayer/dist/controls/fullscreen';
import progress from 'xgplayer/dist/controls/progress';
import volume from 'xgplayer/dist/controls/volume';
import pip from 'xgplayer/dist/controls/pip';
import flex from 'xgplayer/dist/controls/flex';
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper'
import HlsJsPlayer from 'xgplayer-hls.js'
import maskIndex from '@comp/mask'

import EasyPlayer from '@easydarwin/easyplayer'

export default {
  name: 'index',
  components: {
    Icon,
    maskIndex,
    EasyPlayer
  },
  props: {},
  data() {
    return {
      description: '',
      videoSrc: '',
      type: '',
      imgList: [],
      fileType: '',
      instance: null,
      title: '',
      fluent: true,
      autoplay: true,
      live: true,
      dahuaHlsUrl: ''
    }
  },
  computed: {
    isMicroAppEnv() {
      return window && window.__MICRO_APP_ENVIRONMENT__
    }
  },

  methods: {
    restartPlayer(e) {
      console.log(e, 9999);
    },
    centerBoxs(marker, data, type) {
      this.fileType = type;
      this.title = marker.config.tooltip ? marker.config.tooltip.content : marker.config.html;
      let vrId = this.$route.query.vrId;
      if (type === 'videoHls') {
        let videoHls = marker.config.videoHls
        let regex = /^(http|https):\/\//is
        let tag = '';
        if (vrId == '1548241525390139394') {
          tag = 'lz'
        }
        this.$nextTick(_ => {
          let url = ''
          if (regex.test(videoHls)) {
            url = videoHls

            this.instance = new HlsJsPlayer({
              el: this.$refs.video,
              url: url,
              autoplay: true,
              'x5-video-player-type': 'h5',
              useHls: this.isMobile()
            })
          } else {
            // let videoUrl = microService.sysaiot + '/farm/sysDeviceVideo/getId';
            let videoUrl = microService.sysaiot + '/openapi/v2/sysDeviceVideo/getId';
            getAction(videoUrl, {
              id: videoHls,
              tag: tag
            }).then(r => {
              if (r.code === 200) {
                if (r.result.url) {
                  // this.instance.src = r.result.url
                  this.instance = new HlsJsPlayer({
                    el: this.$refs.video,
                    url: r.result.url,
                    autoplay: true,
                    // 'x5-video-player-type': 'h5',
                    // useHls: this.isMobile()
                  })
                }
                //大华视频获取m3u8
                else if (r.result.modelType == 3) {
                  postAction(microService.sysaiot + '/openapi/v2/dhBase/realtime', {
                    channelId: r.result.videoNo,

                  }).then((res) => {
                    // this.instance.src = r.result.url
                    this.dahuaHlsUrl = res.result;
                    this.fileType = 'dahuaHls';
                  });
                } else if (r.result.modelType == '4') {
                  this.dahuaHlsUrl = 'http://47.113.82.41:8866/live?url=' + r.result.videoNo + '.flv'
                  this.fileType = 'dahuaHls';
                }

              } else {
                this.$message.error(r.message)
              }
            })
          }
        })
      } else if (type === 'video') {
        this.$nextTick(_ => {
          this.instance = new Player({
            el: this.$refs.video,
            url: data,
            autoplay: true,
          })
        })

      } else {
        this.imgList = data
        this.instance = new Swiper('.swiper1', {
          loop: true,
          autoplay: {},
          navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          },
          pagination: {
            el: ".swiper-pagination1",
            clickable: true,
          },
          modules: [Autoplay, Navigation, Pagination]
        });
      }
    },
    close() {
      this.$emit('change', 'video');
    },
    errorHandle(e) {
      console.log(e, 999);
    },
  },
  mounted() {

  },
  beforeDestroy() {
    if (this.fileType === 'video' || this.fileType === 'videoHls') {
      this.instance.destroy(true)
    }
    this.$refs.easyplayer && this.$refs.easyplayer.destroyPlayer()
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.centerBox {
  width: 9.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  .title {
    color: #F0F0F0;
    font-size: 32px;
    line-height: 48px;
    text-shadow: 0 1px 2px 0 #000000;
    text-align: center;
  }

  .date {
    font-size: 12px;
    font-weight: 400;
    color: #bfbfbf;
    line-height: 22px;
    text-align: center;
  }

  .video-content {
    width: 9.1rem;
    height: 53.7vh;
    background: #fff;
    margin-top: 10px;
  }
}


.xgplayer {
  width: 100% !important;
  height: 100% !important;
}

.imgsBox {
  .swiper1 {
    overflow: hidden;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;

  }

  .swiper-slide, .swiper-slide img {
    width: 9.1rem;
  }

  .swiper_navigation {
    position: absolute;
    top: calc(50% + 70px);
    transform: translateY(-50%);
    cursor: pointer;

    > div {
      font-size: 40px;
      color: white;
    }
  }

  .swiper-next {
    right: -50px;

  }

  .swiper-prev {
    left: -50px;
  }

  .swiper-pagination1 {
    width: 100%;
    position: absolute;
    bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;

    /deep/ .swiper-pagination-bullet {
      display: inline-block;
      width: 15px;
      height: 15px;
      border: 1px solid #fff;
      border-radius: 50%;
      margin: 0 2px;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background: orange;
      }
    }

  }
}

.wh100 {
  width: 100%;
  height: 100%;
}

.xgplayer {
  width: 100% !important;
  height: 100% !important;
}

.imgsBox {
  .swiper1 {
    overflow: hidden;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;

  }

  .swiper-slide, .swiper-slide img {
    width: 9.1rem;
  }

  .swiper_navigation {
    position: absolute;
    top: calc(50% + 70px);
    transform: translateY(-50%);
    cursor: pointer;

    > div {
      font-size: 40px;
      color: white;
    }
  }

  .swiper-next {
    right: -50px;

  }

  .swiper-prev {
    left: -50px;
  }

  .swiper-pagination1 {
    width: 100%;
    position: absolute;
    bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;

    /deep/ .swiper-pagination-bullet {
      display: inline-block;
      width: 15px;
      height: 15px;
      border: 1px solid #fff;
      border-radius: 50%;
      margin: 0 2px;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background: orange;
      }
    }

  }
}

.wh100 {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 770px) {
  .centerBox {
    width: 100vw !important;
    height: 100vh !important;

    .title {
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 20px;
      font-size: 15px !important;
      line-height: 36px !important;
      margin-top: 15px;
    }

    .video-content {
      width: 100vw !important;
      height: 100vh !important;
      margin-top: 0 !important;
      background: transparent !important;
    }
  }

  .imgsBox {
    .swiper-slide, .swiper-slide img {
      width: 100vw;
      object-fit: none;
    }

    .swiper_navigation {
      z-index: 10;
      top: 50%;


    }

    .swiper-next {
      right: 15px;
    }

    .swiper-prev {
      left: 15px;
    }
  }
}

</style>

