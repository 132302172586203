<template>
  <div class="vr main">
    <div class="container">
      <span id="pcontainer"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "vr",
  props: {
    vrUrl: {
      type: Object,
      default: ""
    }
  },
  data() {
    return {
      // tweening 聚焦参数
      parameters: {
        duration: 3000,//移动过度时间
        curve: 'Exponential',//曲面
        easing: 'Out',//小心缓缓地移动
        iterative: false //反复的
      },
      panorama: null,
      viewer: null,
      index: 0,
      modeIndex: 1,
      baseScale: 300,//基准比例尺
      panoramas: {},
      curves: [],
      easings: [],
      isRightClick: false,
      vrAngle: {
        type: 'normal',
        //拍摄距离  视野角值越大，场景中的物体越小,实现小行星视角，近的物体拉长
        fov: 75,
        //球缓冲几何体设置 radius：球体半径，默认为1。widthSegments：水平分段数（沿着经线分段），最小值为3，默认值为8。垂直分段数（沿着纬线分段），最小值为2，默认值为6。
        geometry: { radius: 500, widthSegments: 100, heightSegments: 100 },
        //相机位置
        position: { x: 0, y: 0, z: 10, },
        //设置相机焦点-视觉中心点
        Vector3: { x: 0, y: 0, z: 10, },
        //最大放缩距离
        maxDistance: 400,
        //最小放缩距离
        minDistance: 100,
        //旋转速度
        rotateSpeed: 1,
        //最大上下旋转角度
        maxPolarAngle: Math.PI / 1.3,
        //最小上下旋转角度
        minPolarAngle: Math.PI / 5,
        //开启旋转
        autoRotate: false,
        //鼠标操作事件后多少毫秒开始旋转
        RotateActivationDuration: 5000,
      },
      vrMap: { name: 'panorama1', url: 'https://psge.sidwit.com/adopt/temp/quanjing5_1657332877399.jpg', title: '全景图', type: 'ImagePanorama' }
    }
  },
  methods: {
    // curves = getPropertyArray(TWEEN.Easing);

    //全景图
    setPanorama(item) {
      this.panoramas[item.name] = new PANOLENS.ImagePanorama(item.url);
    },
    //设置相机、场景、渲染器等
    setViewer() {
      //定义相机 场景等初始值
      this.viewer = new PANOLENS.Viewer({
        cameraFov: this.vrAngle.fov,//相机视野距离
        autoHideInfospot: false,//单击非活动区域时自动隐藏信息点
        controlButtons: ['fullscreen'],//底部控制按钮 'fullscreen', 'setting', 'video' 
        container: document.querySelector('#pcontainer'),//容器
        autoRotate: this.vrAngle.autoRotate,//是否自动旋转
        autoRotateSpeed: this.vrAngle.rotateSpeed,//速度
        autoRotateActivationDuration: this.vrAngle.RotateActivationDuration,//无用户交互时自动旋转前的持续时间（毫秒）
      });

      //循环地图数组并将地图数据加载到场景种
      this.vrMap.url = this.vrUrl.url;
      console.log('this.vrMap',this.vrMap,this.vrUrl.url);
      this.setPanorama(this.vrMap);
      this.viewer.add(this.panoramas[this.vrMap.name]);

      this.viewer.renderer.sortObjects = true;
    },
    //设置菜单
    setMenu() {
      let controlsMenu = {
        //控制项目
        close: {
          style: {
            backgroundImage: 'url(/imgs/vr/close.png)',
            float: 'left',
            backgroundSize: '22px 22px',
            color: '#fff'
          },
        },
        //控制项目
        Vr: {
          style: {
            backgroundImage: 'url(/imgs/vr.svg)',
            float: 'left',
            backgroundSize: '100%'
          },
        },
      };
      for (let i in controlsMenu) {
        let item = controlsMenu[i];
        let that = this;
        if(i == 'Vr'){
          item.onTap = function () {
            that.setVr()
          }
        }
        else{
          item.onTap = function () {
            that.close()
          }
        }
        this.viewer.appendControlItem(item);
      }
    },
    //设置VR
    setVr() {
      this.modeIndex = this.modeIndex >= 2 ? 0 : this.modeIndex + 1;
      switch (this.modeIndex) {
        case 0: this.viewer.disableEffect(); break;
        case 1: this.viewer.enableEffect(PANOLENS.MODES.CARDBOARD); break;
        case 2: this.viewer.enableEffect(PANOLENS.MODES.STEREO); break;
        default: break;
      }
    },
    close(){
      this.$emit('closeVr',this.vrUrl)
    }
  },
  mounted() {
    this.setViewer();
    this.setMenu();
    this.setVr();
  },
}
</script>

<style lang="less" scoped>
.vr {
  width: 100%;
  height: 100%;
  color: #333;

  .container {
    width: 100%;
    height: 100%;

    #pcontainer {
      float: left;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
</style>