<template>
  <div class="vrIot" v-if="visible">
    <img src="/imgs/vr/close.png" class="close" @click="handleClose"></img>
    <div class="top">
      <p>{{title}}</p>
      <p>{{time}}</p>
      <div>
        <div v-for="(item,index) in list" :key="index" @click="handleSelect(item,index)" :class="idx==index?'active':''">
          <img :src="item.url" alt="">
          <div>
            <p>{{item.val}}<span>{{item.unit}}</span></p>
            <p>{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="operation">
        <a-range-picker
            dropdownClassName="feedCalanderDropClass"
            :allowClear="false"
            separator=""
            :disabled-date="disabledDate"
            v-model="date"
            :format="dateFormat"
            @change="panelChange"
          />
        <div>
          <p v-for="(item,index) in ['天','月','年']" 
            :key="index" 
            @click="daysChange(index)" 
            :class="daysIdx==index?'active':''"
          >{{item}}</p>
        </div>
      </div>
      <div class="echart" ref="echart"></div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from "@api/manage";
import * as echarts from "echarts";

import dayjs from "dayjs";
import moment from "moment";
export default {
  name: "index",
  props: {},
  data() {
    return {
      description: "",
      visible: false,
      title: "",
      list: [],
      time: "",
      idx: 0,
      daysIdx: 0,
      deviceId: "",
      elementId: "",
      url: {
        deviceList: "/openapi/v2/iot/deviceVal/getNewDeviceInfo",
        analysisList: "/openapi/v2/iot/deviceVal/devicehistorylist",
      },
      lineStyleColor: {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: "rgba(37, 255, 124, 0.5)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(62, 193, 242, 0)", // 100% 处的颜色
          },
        ],
        global: false, // 缺省为 false
      },
      //charts
      chartTime: "DAY",
      format: "yyyy-MM-dd",
      detailObj: [],
      times: [],
      limit: "1",
      dateStr: [],
      //配置单位与名称
      unit: "",
      hovername: "",
      //设置picker日期格式
      date: [
        moment().subtract(15, "days").format("YYYY-MM-DD HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss"),
      ],
      latitude: 0,
      dateFormat: "YYYY-MM-DD",
    };
  },
  methods: {
    centerBoxs(title, data) {
      this.visible = true;
      this.title = title;
      this.getList(data);
    },
    getList(data) {
      let params = { ...data };
      params.propertyArr = data.elementId;
      postAction(this.url.deviceList, params).then((res) => {
        this.list = res.result;
        this.time = res.result[0].time;
        this.deviceId = data.deviceId;
        this.elementId = data.elementId.split(",")[0];
        this.getdetail();
      });
    },
    getdetail() {
      postAction(this.url.analysisList, {
        deviceId: this.deviceId,
        propertyArr: this.elementId,
        agg: "max",
        format: this.format,
        startDate: this.dateStr[0]
          ? this.dateStr[0] + " 00:00:00"
          : moment().subtract(15, "days").format("YYYY-MM-DD HH:mm:ss"),
        endDate: this.dateStr[1]
          ? this.dateStr[1] + " 23:59:59"
          : moment().format("YYYY-MM-DD HH:mm:ss"),
        interval: this.limit,
        intervalUnit: this.chartTime,
        pageNo: 1,
        pageSize: 100,
      }).then((res) => {
        this.detailObj = [];
        res.result.data.records.map((item) => {
          this.detailObj.push([item.currydate, item[this.elementId]]);
        });
        this.$nextTick((e) => {
          this.initChart();
        });
      });
    },
    initChart() {
      let chart = echarts.init(this.$refs.echart);
      this.detailObj.reverse();
      let option = this.getOption(this.detailObj);
      option && chart.setOption(option);
    },
    getOption(obj) {
      this.times = obj;
      return {
        legend: {
          data: [],
        },
        grid: {
          bottom: "10%",
          left: 45,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(37, 255, 255,0.2)",
              type: "solid",
            },
          },
          backgroundColor: "rgba(12, 12, 13, 0.4)",
          borderColor: "rgba(12, 12, 13, 0.4)",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
          },
          formatter: (params, ticket) => {
            let d = params[0];
            let icon = `<div style="display:flex;"><div style="width: 20px;max-height:2px;margin: 10px 5px 0 0; border: 1px solid #25FF7C;border-radius:2px;position:relative;">
              <div style="width: 8px;height:8px;background-color:white;border:2px solid #25FF7C;border-radius:50%;position: absolute;left:5px;top: -4px"></div>
            </div>${d.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${d.data[1]}${this.unit}</div>`;
            return `<div style="font-size:12px;font-weight:400;">${d.data[0]}<br />${icon}</div>`;
          },
          // extraCssText: "width:160px"
        },
        yAxis: [
          {
            type: "value",
            name: this.unit,
            // axisTick: {
            //   show: false
            // },

            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(37, 255, 255,0.2)",
              },
            },
            nameTextStyle: {
              color: "#DDDDDD",
              fontSize: 12,
            },

            axisLabel: {
              color: "#DDDDDD",
              formatter: (value) => value,
            },
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              color: "#DDDDDD",
              fontSize: 12,
            },
            axisTick: {
              lineStyle: {
                color: this.lineStyleColor,
              },
              inside: true,
            },
            axisLine: {
              lineStyle: {
                color: this.lineStyleColor,
              },
            },
          },
        ],
        series: [
          {
            name: this.hovername,
            type: "line",
            smooth: true,
            showSymbol: false,

            itemStyle: {
              normal: {
                color: "#25FF7C",
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(37, 255, 124, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(62, 193, 242, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.times,
          },
        ],
      };
    },
    //选中指标
    handleSelect(item, index) {
      this.idx = index;
      this.elementId = item.id;
      this.getdetail();
    },
    //picker
    panelChange(date, dateStr) {
      this.dateStr = dateStr;
      this.getdetail();
    },
    disabledDate(current) {
      return current && current > dayjs().endOf("day");
    },
    daysChange(value) {
      this.daysIdx = value;
      if (value == 0) {
        this.format = "yyyy-MM-dd";
        this.chartTime = "DAY";
        this.limit = "1";
      } else if (value == 1) {
        this.format = "yyyy-MM";
        this.chartTime = "DAY";
        this.limit = "30";
      } else {
        this.format = "yyyy";
        this.chartTime = "DAY";
        this.limit = "365";
      }
      this.getdetail();
    },
    //点击关闭
    handleClose() {
      this.visible = false;
      this.$emit('chenge','iot');
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.vrIot {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  > .close {
    width: 0.32rem;
    height: 0.32rem;
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    cursor: pointer;
  }
  > .top {
    width: 50%;
    margin: auto;
    padding-top: 7.8vh;
    > p {
      &:nth-child(1) {
        width: 100%;
        line-height: 6vh;
        text-align: center;
        font-size: 0.32rem;
        color: #f0f0f0;
        margin-bottom: 0 !important;
      }
      &:nth-child(2) {
        width: 100%;
        line-height: 2vh;
        text-align: center;
        font-size: 0.12rem;
        color: #bfbfbf;
        margin-bottom: 2vh !important;
      }
    }
    > div {
      width: 100%;
      height: 29vh;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      > .active {
        border: 2px solid #47ff84;
      }
      > div {
        width: 24%;
        height: 9vh;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        margin-right: 1%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1vh;
        cursor: pointer;
        > img {
          width: 0.5rem;
          height: 0.5rem;
        }
        > div {
          width: 55%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          > p {
            width: 100%;
            margin-bottom: 0 !important;
            &:nth-child(1) {
              font-size: 0.24rem;
              color: #00ffee;
              margin-bottom: 1vh !important;
              > span {
                font-size: 0.16rem;
              }
            }
            &:nth-child(2) {
              font-size: 0.12rem;
              color: #dddddd;
            }
          }
        }
      }
    }
  }
  > .bottom {
    width: 50%;
    height: 45%;
    margin: auto;
    margin-top: 5.8vh;
    background: rgba(0, 0, 0, 0.5);
    > .operation {
      width: 100%;
      height: 15%;
      display: flex;
      align-items: center;
      margin-left: 0.2rem;
      > div {
        width: 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        > .active {
          border: 1px solid #47ff84;
        }
        > p {
          width: 0.22rem;
          width: 0.22rem;
          margin-bottom: 0 !important;
          cursor: pointer;
          text-align: center;
        }
      }
    }
    > .echart {
      width: 100%;
      height: 85%;
    }
  }
}
/deep/ .ant-input {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #47ff84;
  color: #dddddd;
  .ant-calendar-range-picker-separator {
    background: url("../../../../../public/imgs/vr/icon_clander.png") center
      center no-repeat;
    width: 0.16rem;
    height: 0.16rem;
    vertical-align: sub;
  }
}
</style>