var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"appear":"","enter-active-class":"animate__fadeInUpBig","leave-active-class":"animate__fadeOutDownBig","name":"animate__animated animate__bounce animate__faster"}},[(_vm.advancedSetting && _vm.advancedSetting.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"vr-advancedSetting-box",on:{"click":function($event){$event.stopPropagation();return _vm.initClick.apply(null, arguments)},"contextmenu":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"swiper swiper2"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.advancedSetting),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide",on:{"click":[function($event){_vm.currentIndex = index},function($event){$event.stopPropagation();return _vm.slideClick(item)}]}},[_c('div',{staticClass:"icon",class:[
              {'gif1': item.icon === 'gif'}
              ],style:({
              'background-image': 'url(' + item.image + ')',
                })}),_c('div',{staticClass:"vr-advancedSetting-title"},[_c('div',{staticClass:"name",class:[
                  {
                    animate_title: _vm.animateTitleCalc(index),
                  },
                  {
                    cur: _vm.currentIndex === index
                  }
                ]},[_c('span',[_vm._v(_vm._s(item.title))])])])])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }