<template>
  <div id="viewer"></div>
</template>

<script>
import {getAction, getFileAccessHttpUrl, microService} from "@api/manage";

//vr
import {Viewer, Animation} from "photo-sphere-viewer";

import {MarkersPlugin} from "photo-sphere-viewer/dist/plugins/markers"; //标注插件
import { EquirectangularTilesAdapter } from 'photo-sphere-viewer/dist/adapters/equirectangular-tiles'; //瓦片插件

import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import "photo-sphere-viewer/dist/plugins/markers.css";

export default {
  name: "Scene",
  components: {
  },
  data() {
    return {
      vrId: 0,

      //VR 开始
      viewer: null,
      isFirst: true,

      sceneList: [],
      sceneNum: 0,
      sceneParams: {
        width: 0,
        xnum: 0,
        ynum: 0
      },
      background: 'url(https://psge.sidwit.com/rysev/temp/db-bj.jpg) center center no-repeat'
    }
  },
  computed: {
    logoImgSrc() {
      let url = logoImg;
      if (this.vrAppInfo.id) {
        let config = this.vrAppInfo.vrConfig;
        try {
          let globalHeader = JSON.parse(config.globalHeader);
          if (globalHeader.image) {
            return getFileAccessHttpUrl(globalHeader.image);
          }
        } catch (e) {
        }
      }
      return url;
    },
  },
  mounted() {

  },
  created() {
    let vrId = this.$route.query.vrId;
    this.sceneId = this.$route.query.sceneId;
    this.getVrAppInfo(vrId);
  },
  methods: {
    // 请求应用信息
    getVrAppInfo(id) {
      getAction(microService.sysaiot + "/openapi/v2/vr/info/sceneList", {
        vrId: id,
      }).then((res) => {
        if (res.code === 200) {
          let vrScene = res.result.vrScene;
          let scene = {};
          for(let i in vrScene){
            for(let j in vrScene[i].vrInfoSceneList){
              if(vrScene[i].vrInfoSceneList[j].id == this.sceneId){
                scene = vrScene[i].vrInfoSceneList[j];
                break;
              }
            }
          }

          if(scene.url){
            this.setViewer(scene);
          }
        }
      });
    },
    setViewer(scene) {
      let scenePath = scene.url.replace(/s.jpg/g,'');
      let ViewerParams = {
        container: document.querySelector("#viewer"),
        adapter: EquirectangularTilesAdapter,
        navbar: false,
        panorama: {
          width: scene.width,
          cols: scene.ynum,
          rows: scene.xnum,
          baseUrl: scene.url,
          tileUrl: (col, row) => {
              return `${scenePath}${col}_${row}.jpg`;
          },
        },
        autorotateSpeed: 0.08,
        autorotateIdle: true,
        loadingTxt: '加载中……'
      };
      
      this.viewer = new Viewer(ViewerParams);
      this.viewer.startAutorotate();
    },
  },
  beforeDestroy() {
  }
}
</script>

<style lang="less" scoped>
#viewer{
  width: 100%;
  height: 100%;
}
</style>