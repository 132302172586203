<template>
  <div id="app" :class="[{
    'micro-app': isMicroAppEnv
  }]">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  computed:{
    isMicroAppEnv() {
      return window && window.__MICRO_APP_ENVIRONMENT__
    }
  }
}
</script>
<style>
#app {
  width: 100vw;
  height: 100vh;
}

#app.micro-app {
  width: 100%;
  height: 100%;
}
</style>
