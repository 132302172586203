<template>
  <div class="share-container" @click.stop="() => {}">
    <div class="share-container-box">
      <div class="title">
        <span>分享</span>
        <span class="close" @click.stop="close"><Icon style="color: white" type="close"></Icon></span>
      </div>
      <div class="share-content">
        <div class="switchType">
          <div :class="[{ active: currentType === 0 }]" @click="currentType = 0">分享当前场景</div>
          <div :class="[{ active: currentType === 1 }]" @click="currentType = 1">分享本项目</div>
        </div>
        <div class="qrcode">
          <div class="qrcode-img">
            <vue-qr v-show="currentType === 0" :margin="10" :size="130" :text="urlStr"></vue-qr>
            <vue-qr v-show="currentType === 1" :margin="10" :size="130" :text="urlStr"></vue-qr>
          </div>
        </div>
        <div class="clipLink">
          <div class="clipLink-item">
            <div class="clipLink-item-label">作品网址</div>
            <div class="clipLink-item-r" style="margin-left: 36px; width: calc(100% - 100px)">
              <div class="url">
                <textarea disabled>{{ urlStr }}</textarea>
              </div>
              <button class="copyBtn" @click="copy(urlStr)">复制</button>
            </div>
          </div>
          <div class="clipLink-item">
            <div class="clipLink-item-label">嵌入网站代码</div>
            <div style="display: flex; flex-direction: column; width: calc(100% - 100px); margin-left: 36px">
              <div class="clipLink-item-r">
                <div class="url">
                  <textarea disabled>{{ iframeStr }}</textarea>
                </div>
                <button class="copyBtn" @click="copy(iframeStr)">复制</button>
              </div>
              <div class="clipLink-item-r-desc">
                <div>
                  如嵌入后如果显示异常，请在html页面的head里加入以下代码： &lt;meta content='width=device-width,
                  user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0' name='viewport' /&gt;
                </div>
              </div>
            </div>
          </div>
<!--          <div class="clipLink-item">-->
<!--            <div class="clipLink-item-label">分享到</div>-->
<!--            <div style="display: flex; flex-direction: column; width: calc(100% - 100px); margin-left: 36px">-->
<!--              <div class="shares" @click="shareBtn('wechat')">-->
<!--                <svg-icon class="icon wechat" icon-class="wechat"></svg-icon>-->
<!--                <div class="desc">分享到微信</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {copy as copyEvent} from '@/utils/util'
import VueQr from 'vue-qr'
import Icon from 'ant-design-vue/lib/icon'
import 'ant-design-vue/lib/icon/style/css'
import {getAction} from "@/api/manage";

export default {
  name: 'index',
  components: {
    VueQr,
    Icon
  },
  props: {
    selectSceneData: {
      type: Object,
      default: () => ({
        currentSceneIndex: this.currentIndex,
        currentGroupIndex: this.currentGroupIndex,
        vrAppInfo: [],
      })
    }
  },
  data() {
    return {
      description: '',
      currentType: 0,
      isWxInitSuccess: false,
    }
  },
  computed: {
    urlStr() {
      let host = window.location.host
      let protocol = window.location.protocol + '//'
      let url = ''
      let vrId = this.selectSceneData.vrAppInfo.id
      let datas = this.selectSceneData.vrAppInfo.vrScene
      url =  protocol + host + `?vrId=${vrId}`
      if (this.currentType === 0) {
        // 分享场景
        url = url + `&sceneId=${datas[this.selectSceneData.currentGroupIndex].vrInfoSceneList[this.selectSceneData.currentSceneIndex].id}`
      }
      return url
    },
    iframeStr() {
      return `<iframe width="100%" height="100%" frameborder="0" name="waseePanorama" scrolling="no" src="${this.urlStr}"></iframe>`
    }
  },
  mounted() {
    this.initWxConfig()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    copy(str) {
      console.log(str)
      copyEvent(str)
          .then((r) => {
            this.$message.success('复制成功')
          })
          .catch((e) => {
            this.$message.error('复制失败')
          })
    },
    initWxConfig() {
      getAction('/openapi/v2/vr/info/wxGetSignature').then(r => {
        if (r.code === 200) {
          wx.config({
            debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: r.result.appid, // 必填，公众号的唯一标识
            timestamp: r.result.timestamp, // 必填，生成签名的时间戳
            nonceStr: r.result.noncestr, // 必填，生成签名的随机串
            signature: r.result.signature,// 必填，签名
            jsApiList: [
              'updateAppMessageShareData'
            ] // 必填，需要使用的 JS 接口列表
          })
        }
      })

      wx.ready(() => {
        console.log('微信初始化成功')
        // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
        this.isWxInitSuccess = true

      })

      wx.error((res) => {
        // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        console.log(res, '微信验证失败')
      });


    },
    // 更多分享
    shareBtn(t) {
      if (t === 'wechat') {
        if (!this.isWxInitSuccess) {
          this.$message.error('微信分享失败')
          return
        }
        wx.updateAppMessageShareData({
          title: 'vr', // 分享标题
          desc: '农业vr预览', // 分享描述
          link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: '', // 分享图标
          success: function () {
            // 设置成功
            console.log('分享成功')
          }
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.share-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.72);
  top: 0;
  left: 0;

  .share-container-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-width: 640px;
    background: rgba(0, 0, 0, 0.6);
    padding: 15px;
    border-radius: 15px;

    .title {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin-bottom: 20px;

      .close {
        cursor: pointer;
      }
    }

    .share-content {
      .switchType {
        display: flex;
        justify-content: center;

        > div {
          font-size: 16px;
          margin: 0 12px;
          border-radius: 40px;
          padding: 8px 12px;
          cursor: pointer;

          &.active {
            background-color: rgba(0, 0, 0, 0.5);
            color: #4e85fa;
          }
        }
      }

      .qrcode {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 15px 0;

        .qrcode-img {
          background-color: rgba(0, 0, 0, 0.5);
          display: inline-block;
          padding: 10px;
          border-radius: 5px;
          margin: 15px 0;
          width: 130px;
          height: 130px;
          margin-top: 0;
        }
      }

      .clipLink {
        width: 100%;

        font-size: 14px;

        .clipLink-item {
          display: flex;
          justify-content: space-between;

          &:nth-child(n+2) {
            margin-top: 20px;
          }
        }

        .clipLink-item-label {
          text-align: left;
          width: 100px;
          overflow: hidden;
          color: white;
        }

        .clipLink-item-r {
          width: 100%;
          //width: calc(100% - 100px);
          display: flex;
          //margin-left: 36px;

          .url {
            overflow: hidden;
            width: calc(100% - 64px);
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px 0 0 8px;
            padding: 0 8px;
            display: flex;
            align-items: center;

            textarea {
              white-space: nowrap;
              vertical-align: middle;
              display: inline-block;
              resize: none;
              overflow: hidden;
              font-size: 13px;
              border-radius: 5px;
              height: 20px;
              width: 300%;
              margin: 0;
              padding: 0;
              background: 0 0;
              border: none;

              &::selection {
                background: #1e90ff;
              }
            }
          }

          .copyBtn {
            display: inline-block;
            cursor: pointer;
            background: #5085fb;
            border-radius: 0 8px 8px 0;
            width: 64px;
            text-align: center;
            line-height: 36px;
            border: 0;
          }
        }

        .clipLink-item-r-desc {
          width: 100%;
          //width: calc(100% - 100px);
          //margin-left: 36px;

          & > div {
            text-align: left;
            //padding-left: 124px;
            font-size: 12px;
            color: #666;
            margin-top: 12px;
            line-height: 1.9;

            &::selection {
              background: #1e90ff;
            }
          }
        }
      }
    }
  }
}

.shares {
  margin-bottom: 15px;
  position: relative;
  width: fit-content;

  &:hover {
    .desc {
      opacity: 1;
    }
  }

  .desc {
    position: absolute;
    color: white;
    top: -10px;
    left: 25px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    transition: all 0.3s;
  }

  > div {
    cursor: pointer;
  }

  .icon {
    font-size: 30px;
    line-height: 1;

    &.wechat {
      color: #2ecc71;
    }
  }

}
</style>
