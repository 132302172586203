<template>
  <transition
      appear
      enter-active-class="animate__fadeInUpBig"
      leave-active-class="animate__fadeOutDownBig"
      name="animate__animated animate__bounce animate__faster"
  >
    <div v-show="show" class="vr-switch-scene-box" @click.stop="initClick" @contextmenu.stop="() => {}">
      <div v-show="groupDatas && groupDatas.length > 1" class="group-box">
        <div
            v-for="(item, index) in groupDatas"
            :key="index"
            :class="[
            {
              active: currentGroupIndex === index,
            },
          ]"
            @click="setGroup(index)"
        >
          {{ item.groupName }}
        </div>
      </div>

      <div class="vr-switch-scene">
        <div id="swiperIndex" class="swiper">
          <div class="swiper-wrapper">
            <div
                v-for="(item, index) in groupDatas[currentGroupIndex]
                ? groupDatas[currentGroupIndex].vrInfoSceneList
                  ? groupDatas[currentGroupIndex].vrInfoSceneList
                  : []
                : []"
                :key="index"
                :class="[
                {
                  'vr-switch-scene-item-active': index === currentIndex,
                },
              ]"
                class="vr-switch-scene-item swiper-slide"
                @contextmenu.stop="() => {}"
                @click.stop="() => {
                  setCurrent(index)
                  initClick()
                }"
            >
              <img :src="replaceImage(item.showUrl)" alt=""/>
              <!--              <a-icon v-if="checkdList.indexOf(index) > 0" class="vr-switch-scene-item-view" type="check-circle">-->
              <!--              </a-icon>-->
              <div class="vr-switch-scene-item-title">
                <div
                    :class="[
                    {
                      animate_title: animateTitleCalc(index),
                    },
                  ]"
                    class="name"
                >
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css'
import Swiper from 'swiper'
import {getAction} from '@api/manage'

export default {
  name: 'index',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    vrAppInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      description: '',
      show: true,
      currentIndex: 0,
      checkdList: [0],
      currentGroupIndex: 0,
    }
  },
  computed: {
    groupDatas() {
      return this.vrAppInfo.vrScene || []
    }
  },
  methods: {
    replaceImage(url){
      url = url.replace(/http:\/\/img.xd.sidwit.com/g, 'https://psge.sidwit.com');
      url = url.replace(/http:\/\/vrjs.xd.sidwit.com/g, 'https://psge.sidwit.com');
      return url;
    },
    setCurrent(index) {
      this.currentIndex = index
      this.onChange()
    },
    setGroup(index) {
      this.currentGroupIndex = index
      this.setCurrent(0)
    },
    animateTitleCalc(index) {
      let nodeAll = document.querySelectorAll('.vr-switch-scene-item-title')
      if (!nodeAll) return false
      let node = nodeAll[index]
      if (!node) return false
      let childWidth = node.querySelector('span').offsetWidth

      return childWidth > node.clientWidth
    },
    onChange() {
      this.$emit('change', {
        currentSceneIndex: this.currentIndex,
        currentGroupIndex: this.currentGroupIndex,
        datas: [...this.groupDatas],
      })
    },
    setSceneIndex(sceneId) {
      let {currentIndex, currentGroupIndex} = this.getInfoBySceneId(sceneId)
      this.currentIndex = currentIndex
      this.currentGroupIndex = currentGroupIndex
      this.onChange()
    },
    // 通过场景id 获取 其他数据
    getInfoBySceneId(sceneId) {
      let currentIndex = 0;
      let currentGroupIndex = 0;
      try {
        for (let i = 0; i < this.groupDatas.length; i++) {
          for (let j = 0; j < this.groupDatas[i].vrInfoSceneList.length; j++) {
            if (sceneId === this.groupDatas[i].vrInfoSceneList[j].id) {
              currentIndex = j
              currentGroupIndex = i
              break
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      return {
        currentIndex,
        currentGroupIndex,
      }
    },
    initClick() {
      this.$emit('initClick')
    }
  },
  mounted() {
    this.show = this.visible
    let centeredSlides = false
    
    try {
      let childNodeAll = document.querySelectorAll('.vr-switch-scene .swiper-slide')
      let childWidth = childNodeAll[0].clientWidth * childNodeAll.length
      let swiperWrapperWidth = document.querySelector('.vr-switch-scene .swiper-wrapper').clientWidth
      if(childNodeAll.length > 8){
        centeredSlides = swiperWrapperWidth < childWidth + 12*4
      }
    } catch (e) {

    }

    let mySwiper = new Swiper('#swiperIndex', {
      direction: 'horizontal',
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: centeredSlides,
      freeMode: {
        enabled: true,
        momentum: false, // 关闭惯性滚动
      },
    })

    if (this.$route.query.sceneId) {
      // 有场景ID  指定到具体场景
      let sceneId = this.$route.query.sceneId
      let {currentIndex, currentGroupIndex} = this.getInfoBySceneId(sceneId)
      this.currentIndex = currentIndex
      this.currentGroupIndex = currentGroupIndex
      this.onChange()
    } else {
      this.onChange()
    }

  },
  watch: {
    visible(val) {
      this.show = val
    },
  },
}
</script>

<style lang="less" scoped>
.vr-switch-scene-box {
  position: absolute;
  bottom: 8.1vh;
  width: 98%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  left: 1%;
  overflow: hidden;
  z-index: 999;

  .group-box {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    div {
      width: 66px;
      max-width: 66px;
      height: 44px;
      line-height: 44px;
      padding: 0 5px;
      cursor: pointer;
      text-align: center;
      margin: 0 5px;
      font-size: 10px;

      &.active {
        border-bottom: solid 2px #5085fb;
      }
    }
  }
}

.vr-switch-scene {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 90px;

  #swiperIndex {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      // transform: translate3d(0px, 0px, 0px);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .vr-switch-scene-item {
    width: 70px;
    height: 70px;
    border-radius: 2px;
    background: white;
    position: relative;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .vr-switch-scene-item-view {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.vr-switch-scene-item-active {
      background: #5085fb;

      .vr-switch-scene-item-title {
        .animate_title {
          width: max-content;
          padding-left: 100%;
          animation: animate_title_frames 2.5s linear 0s infinite normal;
        }
      }
    }

    img {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }

    .vr-switch-scene-item-title {
      position: absolute;
      bottom: 2px;
      left: 2px;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.5);
      height: 24px;
      width: calc(100% - 4px);
      font-size: 0.12rem;
      text-align: center;
      line-height: 24px;

      .name {
        white-space: nowrap;
        transform: translateX(-0%);
      }
    }
  }
}

.animate__animated.animate__bounce {
  //--animate-duration: 500ms;
}

@media screen and (max-width: 770px) {
  .vr-switch-scene {
    height: 76px;

    .vr-switch-scene-item {
      width: 56px;
      height: 56px;
      min-width: 56px;
      margin: 0 2px;

      .vr-switch-scene-item-title {
        height: 18px;
        font-size: 10px;
        line-height: 20px;
      }
    }
  }

  .vr-switch-scene-box {
    .group-box {
      div {
        height: 35px;
        line-height: 35px;
      }
    }
  }
}

@keyframes animate_title_frames {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
