<template>
  <div></div>
</template>

<script>
import {getAction} from '@api/manage'

export default {
  name: 'index',
  props: {
  },
  data() {
    return {
      description: '',
    }
  },
  methods: {
    centerBoxs(title,data){
      console.log('centerBoxs',title,data);
    },
    close(){
      this.$emit('chenge','image');
    }
  },
  mounted() {
  },
  watch: {
  },
}
</script>

<style lang="less" scoped>
  
</style>