import axios from 'axios'
import notification from 'ant-design-vue/lib/notification'
import 'ant-design-vue/lib/notification/style'
/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = process.env.VUE_APP_API_BASE_URL || "/jeecg-boot";
//console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  //baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 9000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    console.log("------异常响应------", error.response.status)
    switch (error.response.status) {
      case 403:
        notification.error({ message: '系统提示', description: '拒绝访问', duration: 4 })
        break
      case 500:
        console.log("------error.response------", error.response)
        // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        let type = error.response.request.responseType;
        if (type === 'blob') {
          blobToJson(data);
          break;
        }
        break
      case 404:
        notification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })
        break
      case 504:
        notification.error({ message: '系统提示', description: '网络超时' })
        break
      case 401:
        notification.error({ message: '系统提示', description: '未授权，请重新登录', duration: 4 })
        break
      default:
        notification.error({
          message: '系统提示',
          description: data.message,
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  const token = ''
  if (token) {
    // config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }

  // requestType 请求第三方api

  if (config.url.indexOf('/openapi/v2/iot') > -1) {
    config.baseURL = process.env.VUE_APP_OPEN_BASE_URL
  }

  //update-begin-author:taoyan date:2020707 for:多租户
  // config.headers['tenant-id'] = tenantid
  //update-end-author:taoyan date:2020707 for:多租户
  if (config.method == 'get') {
    if (config.url.indexOf("sys/dict/getDictItems") < 0) {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params
      }
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

/**
 * Blob解析
 * @param data
 */
function blobToJson(data) {
  let fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
      if (jsonData.status === 500) {
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log("blob解析fileReader返回err", err)
    }
  };
  fileReader.readAsText(data)
}

export {
  service as axios
}
